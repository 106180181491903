<template>
 <v-app id="inspire" >

    <div style=" justify-content: space-around" class="mt-5 mb-1">
        <v-row class="pl-5 pr-5 mt-5" >
        <v-col v-if="loadingFlag" cols="12" align="center" justify="center" style="margin-bottom:-10px;">
                <img v-if="!company" @click="$router.push('/')" :height="this.$vuetify.breakpoint.xs ? 80 : 300" class="image-logo2" src="@/assets/img/QUANTUM FRSOLUTIONS TP.png">
                <img v-if="company && company.logo" @click="$router.push('/')" :height="this.$vuetify.breakpoint.xs ? 80 : 300" class="image-logo2" :src="base_url_img+company.logo">
        </v-col>
        </v-row>
        <!-- <v-row align="center" justify="center" class="mt-10">

        <v-col cols="11"  sm="11" md="11" lg="11" xs="10">
            <div class="mt-10"  >
            <h1 class=".font-weight-black mt-10" style="color:#5D6167;" >General information</h1>
          </div>
          <v-row align="center" justify="center" class="mt-10">
            
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;">
                <span style="color:#5D6167;">First Name<span class="required_span">*</span></span>
                <v-text-field
                v-model="partner.first_name"
                outlined
          ></v-text-field>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Last Name<span class="required_span">*</span></span>
                <v-text-field
                v-model="partner.last_name"
                outlined
          ></v-text-field>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Middle Name</span>
                <v-text-field
                v-model="partner.middle_name"
                outlined
          ></v-text-field>
            </v-col>
             <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Suffix</span>
               
          <v-select
                :items="['Jr.','Sr.']"
                placeholder="Select suffix"
                v-model="partner.suffix"
                outlined
          ></v-select>
            </v-col>
             <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Email<span class="required_span">*</span></span>
                <v-text-field
                :disabled="temp_email"
                v-model="partner.email"
                outlined
          ></v-text-field>
            </v-col>
             <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">SSN<span class="required_span">*</span></span>
                <v-text-field
                v-mask="'###-##-####'"
                v-model="partner.ssn"
                outlined
          ></v-text-field>
            </v-col>
             <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">DOB<span class="required_span">*</span></span>
                <v-text-field
                                v-model="dob"
                                color="#009688"
                                placeholder="MM/DD/YYYY"
                                v-mask="'##/##/####'"
                                ref="dob"
                                :suffix="'Age '+ age"
                                :rules="rules"
                                outlined
                            >
                            
                            </v-text-field>
            </v-col>
             <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Phone (Home)</span>
                <v-text-field
                v-mask="'(###) ###-####'"
                v-model="partner.phone_h"
                outlined
          ></v-text-field>
            </v-col>
             <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Phone (Work)</span>
                <v-text-field
                v-mask="'(###) ###-####'"
                v-model="partner.phone_w"
                outlined
          ></v-text-field>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Phone (Mobile)<span class="required_span">*</span></span>
                <v-text-field
                v-model="partner.phone_m"
                v-mask="'(###) ###-####'"
                outlined
          ></v-text-field>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Address<span class="required_span">*</span></span>
                <vuetify-google-autocomplete
                        id="map"
                        v-model="address"
                        placeholder="Enter a location"
                        outlined
                        v-on:placechanged="getAddressData"
                        country="us"
                    ></vuetify-google-autocomplete>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">City</span>
                <v-text-field
                v-model="partner.city"
                outlined
          ></v-text-field>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">State</span>
                <v-select
                :items="stateOptions"
                item-text="name"
                item-value="abbreviation"
                placeholder="Select State"
                v-model="partner.state"
                outlined
          ></v-select>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Zip Code<span class="required_span">*</span></span>
                <v-text-field
                v-model="partner.zip"
                outlined
          ></v-text-field>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:#5D6167;">Driving License Number</span>
                          <v-text-field
                          v-model="partner.driving_license_number"
                          outlined
                    ></v-text-field>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:#5D6167;">Driving License State</span>
                          <v-text-field
                          v-model="partner.driving_license_state"
                          outlined
                    ></v-text-field>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:#5D6167;">Process Email</span>
                          <v-text-field
                          v-model="partner.process_email"
                          outlined
                    ></v-text-field>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:#5D6167;">Process Email Password</span>
                          <v-text-field
                          v-model="partner.process_email_password"
                          outlined
                    ></v-text-field>
                      </v-col>
         
            <v-col cols="12"  sm="12" md="12" lg="12" style="margin-top:-25px;" >
                <vue-recaptcha
                    :sitekey="sitekey"
                    :loadRecaptchaScript="true"
                    ref="recaptcha"
                    type="invisible"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                >
            </vue-recaptcha>
            </v-col>
           <v-col cols="12"  sm="12" md="12" lg="12" >
                <v-btn
                    :disabled="isDisable"
                    color="primary"
                    style="height: 53px;
                        padding: 0 40px;
                        text-transform: none;
                        margin-bottom: 50px;" 
                        @click="checkForm"
                        :loading="loading"
                    :dark="isDark"
                >
                    Submit my information now
                </v-btn>
            </v-col>
            <v-col cols="12" >
                <p style="font-size: 18px;
            color: #999999;
            margin-top:-40px;
            line-height: 150%;" >By entering my contact information and clicking "Submit my information now" I consent by electronic signature to be contacted by telephone or by email.</p>
                <p style="font-size: 18px;
            color: #999999;
            line-height: 150%;" ><v-icon style="font-size: 18px;color: #999999;margin-top:-5px">mdi-lock</v-icon> <span>Secure Area</span> | This website is protected by 256-bit SSL security.<br>
            
          </p>
        </v-col>
          </v-row>
        </v-col>
        </v-row> -->
<v-row class="my-16">
      <v-col lg="8" cols="12" class="mx-auto my-auto my-16">
        <v-card class="shadow border-radius-xl my-10">
          <v-stepper
            alt-labels
            elevation="0"
            v-model="e1"
            class="bg-transparent overflow-visible"
          >
            <div class="pt-1">
              <v-stepper-header
                class="
                  bg-gradient-success
                  shadow-primary
                  border-radius-lg
                  mx-4
                  mt-n10
                "
              >
                <v-stepper-step step="1" color="#344767">
                  <span
                    class="
                      text-white
                      opacity-7
                      text-xs
                      font-weight-normal
                      text-uppercase
                    "
                    >General Info</span
                  >
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2" color="#344767">
                  <span
                    class="
                      text-white
                      opacity-7
                      text-xs
                      font-weight-normal
                      text-uppercase
                    "
                    >Address</span
                  >
                </v-stepper-step>
                <v-divider></v-divider>
      
                <!-- <v-stepper-step step="3" color="#344767">
                  <span
                    class="
                      text-white
                      opacity-7
                      text-xs
                      font-weight-normal
                      text-uppercase
                    "
                    >Grants</span
                  >
                </v-stepper-step>
                <v-divider></v-divider> -->

                <v-stepper-step step="3" color="#344767">
                  <span
                    class="
                      text-white
                      opacity-7
                      text-xs
                      font-weight-normal
                      text-uppercase
                    "
                    >Socials</span
                  >
                </v-stepper-step>

               
              </v-stepper-header>
            </div>
            <v-stepper-items class="border-radius-xl overflow-hidden mt-5">
              <v-stepper-content
                step="1"
                class="bg-white border-radius-xl px-7 pt-2"
              >
                <v-card>
                  <div>
                    <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                      About me
                    </h5>
                    <p class="mb-0 text-sm text-body font-weight-light">
                      Mandatory informations
                    </p>
                    <v-form
                        ref="form1"
                        v-model="validGeneral"
                        lazy-validation
                    >
                    <v-row class="mt-2">
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="First Name"
                          color="#e91e63"
                          v-model="partner.first_name"
                          :rules="rules"
                          required
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Middle Name"
                          color="#e91e63"
                          v-model="partner.middle_name"
                          required
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Last Name"
                          color="#e91e63"
                          :rules="rules"
                          v-model="partner.last_name"
                          required
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                       <v-col sm="6" cols="12" class="py-0" >                            
                        <v-select
                        color="#e91e63"
                        label="Suffix"
                        class="font-size-input input-style"
                                :items="['Jr.','Sr.']"
                                v-model="partner.suffix"
                        ></v-select>
                            </v-col>
                    </v-row>

                    <v-row class="mt-2">
                      
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Email Address"
                          color="#e91e63"
                          required
                          :rules="emailRules"
                          v-model="partner.email"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Last 4 of SSN"
                          color="#e91e63"
                          :rules="rules"
                          v-mask="'####'"
                        v-model="partner.ssn"
                          required
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2">
                      
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                                v-model="dob"
                                color="#e91e63"
                                label="DOB"
                                placeholder="MM/DD/YYYY"
                                v-mask="'##/##/####'"
                                ref="dob"
                                class="font-size-input input-style"
                                :suffix="'Age '+ age"
                                :rules="rules"
                            >
                            
                            </v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        label="Phone (Home)"
                        color="#e91e63"
                                v-mask="'(###) ###-####'"
                                class="font-size-input input-style"
                                v-model="partner.phone_h"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                     <v-row class="mt-2">
                      
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        label="Phone (Work)"
                        color="#e91e63"
                                v-mask="'(###) ###-####'"
                                class="font-size-input input-style"
                                v-model="partner.phone_w"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        label="Phone (Mobile)"
                        color="#e91e63"
                        :rules="rules"
                                v-mask="'(###) ###-####'"
                                class="font-size-input input-style"
                                v-model="partner.phone_m"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                     <v-row class="mt-2">
                      
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        label="Password"
                        :type="showpassword ? 'text' : 'password'"
                        :rules="rules"
                        :append-icon="showpassword ? 'fa-eye' : 'fa-eye-slash'"
                        @click:append="showpassword = !showpassword"
                        color="#e91e63"
                                class="font-size-input input-style"
                                v-model="partner.password"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        :rules="rules"
                        label="Confirm Password"
                        :type="showconfpassword ? 'text' : 'password'"
                        :append-icon="showconfpassword ? 'fa-eye' : 'fa-eye-slash'"
                        @click:append="showconfpassword = !showconfpassword"
                        color="#e91e63"
                                class="font-size-input input-style"
                                v-model="partner.confirm_password"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    </v-form>
                  </div>
                  <div class="text-end">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                        py-5
                        px-6
                        mt-6
                        mb-2
                        me-2
                      "
                      color="primary"
                      @click="$refs.form1.validate() ? e1 = 2 : e1 = 1"
                    >
                      Next
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-content
                step="2"
                class="bg-white border-radius-xl px-7 pt-4"
              >
                <v-card>
                  <div>
                    <h5 class="font-weight-bolder text-h5 text-typo">
                      Address
                    </h5>
                    <p class="mb-0 text-sm text-body font-weight-light">
                      Tell us where are you living
                    </p>
                     <v-form
                        ref="form2"
                        v-model="validAddress"
                        lazy-validation
                    >
                    <v-row class="mt-2">
                           
                      <v-col cols="12" class="py-0">
                            <vuetify-google-autocomplete
                                    id="map"
                                    v-model="address"
                                    label="Address 1"
                                    color="#e91e63"
                                     :rules="rules"
                                    class="font-size-input input-style"
                                    placeholder="Enter a location"
                                    v-on:placechanged="getAddressData"
                                    country="us"
                                ></vuetify-google-autocomplete>
                        <!-- <v-text-field
                          label="Address 1"
                          color="#e91e63"
                          required
                          class="font-size-input input-style"
                        ></v-text-field> -->
                      </v-col>
                    </v-row>
                    <v-row class="mt-2">
                      <v-col cols="12" class="py-0">
                        <v-text-field
                          label="Address 2"
                          color="#e91e63"
                           :rules="rules"
                          required
                          v-model="partner.address"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2">
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="City"
                          color="#e91e63"
                           :rules="rules"
                          v-model="partner.city"
                          required
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="3" cols="12" class="py-0">
                         <v-select
                            :items="stateOptions"
                            label="State"
                          color="#e91e63"
                            item-text="name"
                             :rules="rules"
                            item-value="abbreviation"
                            placeholder="Select State"
                            class="font-size-input input-style"
                            v-model="partner.state"
                    ></v-select>
                      </v-col>
                      <v-col sm="3" cols="12" class="py-0">
                        <v-text-field
                          label="Zip"
                          color="#e91e63"
                          required
                           :rules="rules"
                          v-model="partner.zip"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    </v-form>
                  </div>
                  <div class="d-flex">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        placeholder-lighter
                        text-xs text-dark
                        btn-outline-secondary
                        bg-transparent
                        py-5
                        px-6
                        mt-6
                        mb-2
                        ms-2
                      "
                      @click="e1 = 1"
                    >
                      Prev
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                        py-5
                        px-6
                        mt-6
                        mb-2
                        me-2
                        ms-auto
                      "
                      color="primary"
                      @click="$refs.form2.validate() ?  e1 = 3 : e1 =2"
                    >
                      Next
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>
              <!-- <v-stepper-content
                step="3"
                class="bg-white border-radius-xl px-7 pt-4"
              >
                <v-card>
                  <div>
                    <h5 class="font-weight-bolder text-h5 text-typo">
                      Grants
                    </h5>
                    <p class="mb-0 text-sm text-body font-weight-light">
                      Please provide all grant informations
                    </p>
                    
                    <v-row class="mt-2">
                        <v-col cols="12" class="py-0">
                          <v-radio-group v-model="partner.is_grant">
                                <template v-slot:label>
                                  <div>Are you applying for <strong>grants?</strong></div>
                                </template>
                                <v-radio value="yes">
                                  <template v-slot:label>
                                    <div>Yes</div>
                                  </template>
                                </v-radio>
                                <v-radio value="no">
                                  <template v-slot:label>
                                    <div>No</div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                      </v-col>

         
                    </v-row>
                    <v-form
                        ref="form3"
                        v-model="validAddress"
                        lazy-validation
                    >
                     <v-row class="mt-2" v-if="partner.is_grant == 'yes'">
                        <v-col cols="12" class="py-0">
                            <v-text-field
                              label="Grant Specific Email"
                              color="#e91e63"
                              required
                              :append-icon="'fa-info-circle'"
                              :rules="rules"
                              @click:append="showDialog = true"
                              v-model="grant.grant_email"
                              class="font-size-input input-style"
                            ></v-text-field>
                          </v-col>
                           <v-col cols="12" class="py-0">
                            <v-text-field
                              label="Grant Email Login Password"
                              color="#e91e63"
                              required
                              :rules="rules"
                              v-model="grant.grant_password"
                              class="font-size-input input-style"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                              label="OWNER FULL NAME "
                              color="#e91e63"
                              required
                              :rules="rules"
                              v-model="grant.grant_full_name"
                              class="font-size-input input-style"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="grant.grant_dob"
                                color="#e91e63"
                                label="OWNER DATE OF BIRTH "
                                placeholder="MM/DD/YYYY"
                                v-mask="'##/##/####'"
                                ref="dob"
                                class="font-size-input input-style"
                                :rules="rules"
                            >
                            
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <vuetify-google-autocomplete
                              id="map3"
                              v-model="grant.grant_address"
                              label="OWNER ADDRESS (City,State & Zip code)"
                              class="font-size-input input-style"
                              v-on:placechanged="getAddressData3"
                              country="us"
                          ></vuetify-google-autocomplete>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-radio-group v-model="grant.grant_gender">
                              <template v-slot:label>
                                  <div>OWNER GENDER</div>
                                </template>
                                <v-radio value="Male">
                                  <template v-slot:label>
                                    <div>Male</div>
                                  </template>
                                </v-radio>
                                <v-radio value="Female">
                                  <template v-slot:label>
                                    <div>Female</div>
                                  </template>
                                </v-radio>
                                <v-radio value="Prefer not to say">
                                  <template v-slot:label>
                                    <div>Prefer not to say</div>
                                  </template>
                                </v-radio>
                                   <v-radio value="Other">
                                  <template v-slot:label>
                                    <div>Other</div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                          </v-col>
                           <v-col cols="12" class="py-0">
                            <v-radio-group v-model="grant.grant_ethnicity">
                              <template v-slot:label>
                                  <div>OWNERS ETHNICITY</div>
                                </template>
                                <v-radio value="WHITE">
                                  <template v-slot:label>
                                    <div>WHITE</div>
                                  </template>
                                </v-radio>
                                <v-radio value="BLACK/ AFRICAN AMERICAN">
                                  <template v-slot:label>
                                    <div>BLACK/ AFRICAN AMERICAN</div>
                                  </template>
                                </v-radio>
                                <v-radio value="NATIVE AMAERICAN/ INDEGENOUS PERSONS">
                                  <template v-slot:label>
                                    <div>NATIVE AMAERICAN/ INDEGENOUS PERSONS</div>
                                  </template>
                                </v-radio>
                                   <v-radio value="ASIAN">
                                  <template v-slot:label>
                                    <div>ASIAN</div>
                                  </template>
                                </v-radio>
                                <v-radio value="HISPANIC/ SPANISH">
                                  <template v-slot:label>
                                    <div>HISPANIC/ SPANISH</div>
                                  </template>
                                </v-radio>
                                <v-radio value="Other">
                                  <template v-slot:label>
                                    <div>Other</div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                          </v-col>
                           <v-col cols="12" class="py-0">
                            <v-radio-group v-model="grant.grant_veteran">
                              <template v-slot:label>
                                  <div>Are you a Veteran ?</div>
                                </template>
                                <v-radio value="Yes">
                                  <template v-slot:label>
                                    <div>Yes</div>
                                  </template>
                                </v-radio>
                                <v-radio value="No">
                                  <template v-slot:label>
                                    <div>No</div>
                                  </template>
                                </v-radio>
                                <v-radio value="Prefer not to say">
                                  <template v-slot:label>
                                    <div>Prefer not to say</div>
                                  </template>
                                </v-radio>
                                
                              </v-radio-group>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-radio-group v-model="grant.grant_disabled">
                              <template v-slot:label>
                                  <div>Are you disabled ?</div>
                                </template>
                                <v-radio value="Yes">
                                  <template v-slot:label>
                                    <div>Yes</div>
                                  </template>
                                </v-radio>
                                <v-radio value="No">
                                  <template v-slot:label>
                                    <div>No</div>
                                  </template>
                                </v-radio>
                                <v-radio value="Maybe">
                                  <template v-slot:label>
                                    <div>Maybe</div>
                                  </template>
                                </v-radio>
                                
                              </v-radio-group>
                          </v-col>
                          <v-col cols="12" class="py-0">
                          
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0">Business Informations</h5>
                          <v-divider></v-divider>
                          </v-col>
                           <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="grant.grant_business_start_date"
                                color="#e91e63"
                                label="Business Start Date"
                                placeholder="MM/DD/YYYY"
                                v-mask="'##/##/####'"
                                ref="dob"
                                class="font-size-input input-style"
                                :rules="rules"
                            >
                            
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="grant.grant_business_name"
                                color="#e91e63"
                                label="Business Name"
                                ref="dob"
                                class="font-size-input input-style"
                                :rules="rules"
                            >
                            
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <vuetify-google-autocomplete
                              id="map2"
                              v-model="grant.grant_business_address"
                              label="Business Address (City, State, Zip code)"
                              class="font-size-input input-style"
                              v-on:placechanged="getAddressData2"
                              country="us"
                          ></vuetify-google-autocomplete>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="grant.grant_business_phone"
                                color="#e91e63"
                                label="Business Phone Number"
                                ref="dob"
                                v-mask="'(###) ###-####'"
                                class="font-size-input input-style"
                                :rules="rules"
                            >
                            
                            </v-text-field>
                          </v-col>
                            <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="grant.grant_business_website"
                                color="#e91e63"
                                label="Business Website"
                                ref="dob"
                                class="font-size-input input-style"
                            >
                            
                            </v-text-field>
                          </v-col>
                            <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="grant.grant_business_duns"
                                color="#e91e63"
                                label="Business D-U-N-S Number"
                                ref="dob"
                                v-mask="'###########'"
                                class="font-size-input input-style"
                            >
                            
                            </v-text-field>
                          </v-col>
                           <v-col cols="12" class="py-0">
                          
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0">Business Tax Informations</h5>
                          <v-divider></v-divider>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="grant.grant_business_tax_id"
                                color="#e91e63"
                                label="Business EIN / TAX ID"
                                ref="dob"
                                v-mask="'## #######'"
                                class="font-size-input input-style"
                                :rules="rules"
                            >
                            
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="grant.grant_business_employees"
                                color="#e91e63"
                                label="Number of Employees"
                                ref="dob"
                                class="font-size-input input-style"
                                :rules="rules"
                            >
                            
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                             <v-select
                        color="#e91e63"
                        label="Annual Gross Income"
                        class="font-size-input input-style"
                                :items="['$0-$20,000','$20,000-$50,000','$50,000-$100,000','$100,000+']"
                                v-model="grant.grant_business_income"
                                :rules="rules"
                        ></v-select>
                          </v-col>
                          <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_income_explain"
                                color="#e91e63"
                                label="Income Explanation  (How does this business make money ?)"
                                ref="dob"
                                class="font-size-input input-style"
                                :rules="rules"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_expanses"
                                color="#e91e63"
                                label="Business Expenses  (List Business Bills and amounts)"
                                ref="dob"
                                class="font-size-input input-style"
                                :rules="rules"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" class="py-0">
                              <v-file-input
                                  accept="image/*,application/pdf"
                                  v-model="grant.grant_business_llc"
                                  class="font-size-input input-style"
                                  label="Please Upload your Articles of Operation (LLC) "
                                ></v-file-input>

                          </v-col>
                           <v-col cols="12" class="py-0">
                              <v-file-input
                                  accept="image/*,application/pdf"
                                  v-model="grant.grant_business_ein"
                                  class="font-size-input input-style"
                                  label="Please Upload your EIN Form. "
                                ></v-file-input>

                          </v-col>
                          <v-col cols="12" class="py-0">
                          
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0">Funding Request.</h5>
                          <v-divider></v-divider>
                          </v-col>  
                           <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_request_funding"
                                color="#e91e63"
                                label="How much is your business Requesting in funding?"
                                ref="dob"
                                class="font-size-input input-style"
                                :rules="rules"
                            ></v-textarea>
                          </v-col>
                           <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_funding_used_for"
                                color="#e91e63"
                                label="What would this funding be used for ?"
                                ref="dob"
                                class="font-size-input input-style"
                                :rules="rules"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" class="py-0">
                          
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0">Lets Get to know your business a bit more </h5>
                          <v-divider></v-divider>
                          </v-col>  
                           <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_industry"
                                color="#e91e63"
                                label="What is your business Industry?"
                                ref="dob"
                                class="font-size-input input-style"
                                :rules="rules"
                            ></v-textarea>
                          </v-col>
                           <v-col cols="12" class="py-0">
                             <v-text-field
                                v-model="grant.grant_business_competitors"
                                color="#e91e63"
                                label="List your top 3 competitors "
                                ref="dob"
                                class="font-size-input input-style"
                            ></v-text-field>
                          </v-col>
                           <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_info"
                                color="#e91e63"
                                label="Tell us about your business ( start date, what your specialties are and how do you operate your business?)"
                                ref="dob"
                                class="font-size-input input-style"
                            ></v-textarea>
                          </v-col>
                           <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_info2"
                                color="#e91e63"
                                label="What sets your business aside from your competitors ?"
                                ref="dob"
                                class="font-size-input input-style"
                            ></v-textarea>
                          </v-col>
                           <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_info3"
                                color="#e91e63"
                                label="Why should Grantors Choose your business ?"
                                ref="dob"
                                class="font-size-input input-style"
                                :rules="rules"
                            ></v-textarea>
                          </v-col>
                           <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_info4"
                                color="#e91e63"
                                label="How do you plan to grow your business in the next 3-5 years ? "
                                ref="dob"
                                class="font-size-input input-style"
                                :rules="rules"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" class="py-0">
                           <v-checkbox v-model="agree" :rules="rules">
                                    <template v-slot:label>
                                      <div>
                                        Click here to indicate that you have read and agree to the terms presented in the 
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <a
                                              target="_blank"
                                              href="#"
                                              @click="gotoPrivacy"
                                            >
                                             <span style="color:blue;">Terms and Conditions agreement </span>
                                            </a>
                                          </template>
                                          Read Terms and Conditions
                                        </v-tooltip>
                                      </div>
                                    </template>
                                  </v-checkbox>
                            </v-col>
                      </v-row>
                      </v-form>
                  </div>
                  <div class="d-flex">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        placeholder-lighter
                        text-xs text-dark
                        btn-outline-secondary
                        bg-transparent
                        py-5
                        px-6
                        mt-6
                        mb-2
                        ms-2
                      "
                      @click="e1 = 2"
                    >
                      Prev
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      :loading="loadingSaveButton"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                        py-5
                        px-6
                        mt-6
                        mb-0
                        ms-auto
                      "
                      color="primary"
                      @click="$refs.form3.validate() ?  e1 = 4 : e1 =3"
                    >
                      Next
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content> -->
              <v-stepper-content
                step="3"
                class="bg-white border-radius-xl px-7 pt-4"
              >
                <v-card>
                  <div>
                    <h5 class="font-weight-bolder text-h5 text-typo">
                      Socials
                    </h5>
                    <p class="mb-0 text-sm text-body font-weight-light">
                      Please provide all social details
                    </p>
                    <v-row class="mt-2">
                        <v-col cols="12" class="py-0">
                          <v-text-field
                          label="Driving License Number"
                          v-model="partner.driving_license_number"
                          color="#e91e63"
                          required
                          class="font-size-input input-style"
                    ></v-text-field>
                      </v-col>
                     <v-col cols="12" class="py-0">
                          <v-text-field
                          label="Driving License State"
                          v-model="partner.driving_license_state"
                          color="#e91e63"
                          required
                          class="font-size-input input-style"
                    ></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" class="py-0">
                          <v-text-field
                          label="Process Email"
                          v-model="partner.process_email"
                          color="#e91e63"
                          required
                          class="font-size-input input-style"
                    ></v-text-field>
                      </v-col> -->
                      <!-- <v-col cols="12" class="py-0">
                          <v-text-field
                          label="Process Email Password"
                          v-model="partner.process_email_password"
                          color="#e91e63"
                          required
                          class="font-size-input input-style"
                    ></v-text-field>
                      </v-col> -->
         
                    <v-col cols="12" class="py-0">
                        <vue-recaptcha
                            :sitekey="sitekey"
                            :loadRecaptchaScript="true"
                            ref="recaptcha"
                            type="invisible"
                            @verify="onCaptchaVerified"
                            @expired="onCaptchaExpired"
                        >
                    </vue-recaptcha>
                    </v-col>
                     
                    </v-row>
                  </div>
                  <div class="d-flex">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        placeholder-lighter
                        text-xs text-dark
                        btn-outline-secondary
                        bg-transparent
                        py-5
                        px-6
                        mt-6
                        mb-2
                        ms-2 
                      "
                      @click="e1 = 2"
                    >
                      Prev
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      :loading="loadingSaveButton"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                        py-5
                        px-6
                        mt-6
                        mb-0
                        ms-auto
                      "
                      color="primary"
                      @click="checkForm()"
                    >
                      Submit
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>

             
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDialog"
      width="700"
    >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
          Info
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <p>
            It's quite a daunting task answering the those grants by yourself and all the other agencies back and forth. So we make it easier for you.
            </p><p>
            While you're out taking care of business we're helping you take care of business.
            </p><p>

            To do this for you we need a process email for the task that we are going to do on your behalf.
            </p><p>

            All you have to do is make a process email so that we can check it and respond on your behalf.
            </p><p>

            To make a process email here is an Example. My last name and random numbers @gmail.com  like this —>(doe74564@gmail.com)
            </p><p>
            <a href="https://accounts.google.com/signup/v2/webcreateaccount?hl=en&amp;flowName=GlifWebSignIn&amp;flowEntry=SignUp" target="_blank" rel="nofollow">(CLICK HERE)</a> Once you get signed up enter login in information below.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="green"
            outlined
            :disabled="loadingAssign"
            @click="showDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
    </div>
 </v-app>
</template>

<script>
import DateDropdown from 'vue-date-dropdown'
import VueRecaptcha from 'vue-recaptcha'
import moment from 'moment'
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
// import Vue from 'vue'
export default {
    components: {
        DateDropdown,
        'vue-recaptcha' : VueRecaptcha,
        'RotateLoader': ClipLoader
    },
    data()  {
        return {
            address: '',
            dob:'',
            age: 0,
            e1 : 1,
            loadingSaveButton : false,
            verifyLoad: false,
            verfiedFlag : false,
            verifyUser: '',
            showDialog :false,
            showconfpassword : false,
            agree : false,
            base_url_img : process.env.VUE_APP_WASBI_URL,
            showpassword : false,
            rules: [
                value => !!value || 'Required.',
            ],
            temp_email  : localStorage.getItem('temp_email') ? true : false,
            validGeneral : true,
            validAddress : true,
            validSocial : true,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            loadingFlag : false,
            grant : {},
            partner : {
                first_name : "",
                last_name : '',
                middle_name: '',
                email : localStorage.getItem('temp_email') ? localStorage.getItem('temp_email') : '',
                is_email : false ,
                phone_h : '',
                phone_w : '',
                dob: '',
                is_exist : "",
                ssn : '',
                phone_m : '',
                fax : '',
                address :'',
                address1: '',
                city : '',
                state : '',
                zip : '',
                country :'United States',
                memo : '',
                is_verified:  0,
                document : '',
                suffix:'',
                recaptcha: '',
                user_name: "",
                password: '',
                confirm_password: "",
                crc_id : "",
                driving_license_number : '',
                driving_license_state : "",
                process_email : "",
                process_email_password : "",
                is_grant :'no'

            },
                url : '',
                company : '',
            isDisable :false,
            loading: false,
            isDark: true,
            existOption: ["Yes","No"],
            stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
            year : new Date().getFullYear(),
            form: {
            // Some form values...
                recaptcha: '',
            },
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
        }
    },
    created () {
        this.url = this.$route.params.url ? this.$route.params.url : '';
        if(this.url)
            this.getCompanyInfo(this.url)
        else
            this.loadingFlag = true;
    },
    watch: {
      dob (val) {
        this.age = val ?  moment().diff(this.dob, 'years') :0
        console.log(this.age);
        if(isNaN(this.age))
          this.age = 0;
      },
    },
    beforeCreate: function () {
        if (localStorage.getItem('token')) {
            if(localStorage.getItem('is_admin'))
                this.$router.push('/admin/dashboard')
            else
                this.$router.push('/dashboard')
        }
    },
    methods : {
      gotoPrivacy(){
        window.open('/privacy/'+this.$route.params.url, "_blank");
      },
        getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            console.log(response.data.company);
            this.company = response.data.company;
            this.loadingFlag = true;
          })

},
        onCaptchaVerified: function (recaptchaToken) {
        this.partner.recaptcha = recaptchaToken
        this.validateCaptcha = true
        },
        onCaptchaExpired: function () {
        this.$refs.recaptcha.reset();
        },
         getAddressData3: function (addressData3, placeResultData, id) {
            this.grant.grant_address = addressData3.name;
        },
        getAddressData2: function (addressData2, placeResultData, id) {
            this.grant.grant_business_address = addressData2.name;
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.partner.address = addressData.name;
            this.partner.city = addressData.locality;
            this.partner.state = addressData.administrative_area_level_1;
            this.partner.zip = addressData.postal_code
            console.log(this.partner);
        },
        isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        verifyCrc(){
            let self = this;
            let flag = 1;
            if (!this.partner.crc_id) {
                Vue.$toast.error('Customer ID is required', {
                    position: 'top-right'
                });
                flag = 0;
            }else{
                self.verifyLoad = true;
                axios({
                method: "POST",
                 
                url: "/api/auth/verify-crc",
                data:{crc_id : self.partner.crc_id},
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.data.success == 'True'){
                        self.verifyUser = response.data.data.result;
                        self.verfiedFlag = true;
                    }else{
                        Vue.$toast.error('Invalid customer id', {
                            position: 'top-right'
                        });
                    }
                    self.verifyLoad = false
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    self.verifyLoad = flase;

                });
            }
        },
        autofill(){
            let self = this;
            self.partner.first_name = self.verifyUser.firstname
            self.partner.last_name = self.verifyUser.lastname
            if(!self.temp_email)
                self.partner.email = self.verifyUser.email
            self.partner.middle_name = self.verifyUser.middlename
            self.partner.phone_m = self.verifyUser.phone_mobile
            self.partner.phone_h = self.verifyUser.phone_home
            self.partner.phone_w = self.verifyUser.phone_work
            self.partner.ssn = self.verifyUser.ssno
            self.address = self.verifyUser.street_address
            if(self.verifyUser.state){
                self.stateOptions.forEach(element => {
                    if(self.verifyUser.state.toUpperCase()  == element.name)
                        self.partner.state = element.abbreviation
                });
            }
            self.partner.zip = self.verifyUser.zip
            self.partner.city = self.verifyUser.city
            if(self.verifyUser.birth_date){
                var temp_dob = self.verifyUser.birth_date.split("/");
                self.partner.dob = temp_dob[1]+'.'+temp_dob[0]+'.'+temp_dob[2]
                console.log(self.partner.dob);
            }
            
        },
        checkForm(){
            let self = this;
            let flag = 1;
            console.log(this.partner);
            
            if (!this.partner.first_name) {
                Vue.$toast.error('First Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.last_name && flag == 1) {
                Vue.$toast.error('Last Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.email && this.partner.is_email == false && flag == 1) {
                Vue.$toast.error('Email is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.ssn && flag == 1) {
                Vue.$toast.error('SSN is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            this.partner.dob = this.dob;
            if (!this.partner.dob && flag == 1) {
                Vue.$toast.error('DOB is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (reg.test(this.partner.email) == false && this.partner.is_email == false  && flag == 1) {
                Vue.$toast.error('Invalid email format', {
                    position: 'top-right'
                });
                flag = 0;
            }

            if (!this.partner.phone_m && this.partner.phone_m == false && flag == 1) {
                Vue.$toast.error('Phone (Mobile) is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            
            if (!this.partner.address && this.partner.address == false  && !this.partner.city && this.partner.city == false   && !this.partner.state && this.partner.state == false  && !this.partner.zip && this.partner.zip == false  &&  flag == 1) {
                Vue.$toast.error('Address is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
             if (!this.partner.zip && flag == 1) {
                Vue.$toast.error('Zip Code is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            // if (!this.partner.crc_id && this.partner.crc_id == false && flag == 1) {
            //     Vue.$toast.error('CRC ID is required', {
            //         position: 'top-right'
            //     });
            //     flag = 0;
            // }
            this.partner.user_name = this.partner.email;
            // if (!this.partner.user_name && this.partner.user_name == false && flag == 1) {
            //     Vue.$toast.error('User name is required', {
            //         position: 'top-right'
            //     });
            //     flag = 0;
            // }
            if (!this.partner.password && this.partner.password == false && flag == 1) {
                Vue.$toast.error('Password is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.confirm_password && this.partner.confirm_password == false && flag == 1) {
                Vue.$toast.error('Confirm password is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (this.partner.password != this.partner.confirm_password && flag == 1) {
                Vue.$toast.error('Confirm password did not match with password', {
                    position: 'top-right'
                });
                flag = 0;
            }
            
            if (flag == 1) {
                this.loadingSaveButton = true;
                let is_verified = localStorage.getItem('email_verified') ? localStorage.getItem('email_verified') : 0
                this.partner.dob = this.dob;
                this.partner.is_verified = is_verified;
                this.partner.company_id = this.company ? this.company.id : '';
                this.isDark = false;
                this.isDisable = true;
                const data = new FormData();
                for (var key in self.partner) {
                  data.append(key,self.partner[key]);
                }
                data.append('file_1', this.grant.grant_business_llc);
                data.append('file_2', this.grant.grant_business_ein);
                data.append('grant',JSON.stringify(this.grant));
                axios({
                method: "POST",
                url: "/api/auth/register",
                data:data,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                        let result = response.data.data;
                        //self.$router.push('/login');
                        if(is_verified == 0)
                            Vue.$toast.success('You have successfuly registered. Please check your inbox for confirm your email', {
                            position: 'top-right'
                            }); 
                        else
                            Vue.$toast.success('You have successfuly registered.', {
                            position: 'top-right'
                            }); 
                        localStorage.setItem("temp_email", '');
                        localStorage.setItem('email_verified','')
                        localStorage.setItem('temp_pass','')
                        localStorage.setItem("token", response.data.token.token);
                        localStorage.setItem("user_id", result.user_id);
                        localStorage.setItem("email", result.email);
                        localStorage.setItem("name", result.first_name+' '+result.last_name);
                        localStorage.setItem("document_flag",false);
                        localStorage.setItem("agreement_flag",0);
                        localStorage.setItem("is_admin",0);
                        localStorage.setItem("role", JSON.stringify({name : 'client'}));
                        //   console.log(response.data);
                        if(localStorage.getItem('temp_request_id'))
                            this.$router.push("/restart-process/"+localStorage.getItem('temp_request_id'));
                        if(localStorage.getItem("document_flag") != 'false')
                            this.$router.push("/dashboard");
                        else
                            this.$router.push("/dashboard");
                        location.reload();
                        this.isDark = true;
                        this.loadingSaveButton = false;
                        this.isDisable = false;                    
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    this.isDark = true;
                    this.isDisable = false;
                    this.loadingSaveButton = false;
                    if(err.response.data.flag){
                        if(err.response.data.flag == 2){
                            Vue.$toast.error('Company exist with same email address!', {
                            position: 'top-right'
                            });
                        }
                        if(err.response.data.flag == 3){
                          Vue.$toast.error('Client exist with same email address!', {
                            position: 'top-right'
                            });
                        }
                    }else{
                      let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                      if(data.email){
                          Vue.$toast.error(data.email[0], {
                          position: 'top-right'
                          });
                      }else if(data.password){
                          Vue.$toast.error(data.password[0], {
                          position: 'top-right'
                          });
                      }
                      else if(data.user_name){
                          Vue.$toast.error(data.user_name[0], {
                          position: 'top-right'
                          });
                      }
                      else{
                          Vue.$toast.error(data, {
                          position: 'top-right'
                          });
                      }
                  }
                    self.onCaptchaExpired()

                });

            }
        }
    }
}
</script>

<style>
/* .image-logo2{
position: absolute;
margin: -78px -5px -4px 170px;
} */
.date-dropdown-container{
    height: 85px;
}
.date-dropdown-select{
    border: 1px solid #9E9E9E!important;
    padding: 15px!important;
}
.required_span{
    color:red;
}
</style>