<template>
<v-app id="inspire" style="background:#F9F9F9;">
<vue-header-admin></vue-header-admin>
    <v-main>
    <v-container fluid class="px-6 py-6">
        <v-row
            align="center"
            justify="center"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-2" id="basic" style="margin-bottom: 100px;">
                <v-card-text >
                  <v-row>
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Clients({{totalCount}})</h5>
                    </v-col>
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                    <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                    </v-col>
                     <v-col cols="12"
                      md="12" >
                         <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        @click="$router.push('add-client')"
                                    >
                                        Add Client
                                    </v-btn>
                                    <v-btn
                                    color="teal"
                                    class="
                                    ont-weight-bolder
                                      btn-default
                                      mr-5
                                      py-4
                                      px-8
                                      ms-auto
                                      mt-sm-auto mt-4
                                                    
                                                  "
                                        small
                                        dark
                                        @click="dialogImport = true"
                                    >
                                        Import from CSV
                                    </v-btn>
                                    <vue-json-to-csv :json-data="users"
                                    :labels="{ first_name: { title: 'First Name' },middle_name: { title: 'Middle Name' },last_name: { title: 'Last Name' },suffix: { title: 'Suffix' },email : {title : 'Email'},phone_h : {title : 'Phone Home'},phone_w: { title: 'Phone Work' },phone_m: { title: 'Mobile' },dob : {title : 'Date Of Birth'},address_1: { title: 'Address line 1' },city: { title: 'City' },state: { title: 'State' },zip: { title: 'Zip' },status: { title: 'Status' },created_at : {title : 'Created At'} }"
                                    csv-title="clients"
                                    >
                                     <v-btn
                                    color="orange"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                    dark
                                        small
                                    >
                                        Export to csv
                                    </v-btn>
                                  </vue-json-to-csv>
                                    <v-btn
                                    color="grey"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        dark
                                        @click="selected_bot = '',creditCheck()"
                                    >
                                        Mass Request
                                    </v-btn>
                                    <v-btn
                                    color="red"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        dark
                                        @click="deleteItem('all')"
                                    >
                                        mass delete
                                    </v-btn>
                                     <v-btn
                                    color="green"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        dark
                                        @click="changeStatus('all',0)"
                                    >
                                        mass activate
                                    </v-btn>
                                    
                                    <v-btn
                                    color="red"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        dark
                                        @click="changeStatus('all',1)"
                                    >
                                        mass Inactivate
                                    </v-btn>
                                    <v-btn
                                    color="green"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        dark
                                        v-if="role.name=='super_admin'"
                                        @click="changeStatusGrant('all',0)"
                                    >
                                        mass Grant enable
                                    </v-btn>
                                    <v-btn
                                    v-if="role.name=='super_admin'"
                                    color="red"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        dark
                                        @click="changeStatusGrant('all',1)"
                                    >
                                        mass grant disable
                                    </v-btn>
                                    <v-btn
                                    color="blue"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        dark
                                        @click="dialogAssign = true"
                                    >
                                        Assign to
                                    </v-btn>
                      </v-col>
                    <v-col cols="12"
                      md="12" >
                            <v-text-field
                               v-model="search"
                                label="Search"
                                single-line
                                
                                clearable
                                hide-details
                            >
                                <template v-slot:append>
                                
                                </template>
                                <template v-slot:append-outer>
                                    <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        @click="getUsers"
                                    >
                                        Submit
                                    </v-btn>
                                </template>
                            </v-text-field>
                    </v-col>
                    
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_jobs"
                            :options.sync="datatable_options"
                            v-model="selectedItems"
                            :items="users"
                            item-key="user_id"
                            loading-text="Loading... Please wait"
                            :page.sync="page"
                            show-select
                            :footer-props="{
                                    firstIcon: '',
                                    lastIcon: '',
                                    prevIcon: '',
                                    nextIcon: '',
                                    'page-text': false,
                                    'items-per-page-options': [10, 50, 100,-1]
                                  }"
  
                            :items-per-page="itemsPerPage"
                            class="table"
                        >
                        <template v-slot:item.name="{ item }">
                            <a style="text-decoration: none;color: #2196F3;" :href="'/admin/client-profile-detail/'+item.user_id">{{item.first_name}} {{item.last_name}}</a>
                        </template>
                        <template v-slot:item.status="{ item }">
                            {{item.status == 1 ? 'Active' :'Inactive'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                        <template v-slot:item.company="{ item }">
                            {{item.name ? item.name : 'Idealergy'}}
                        </template>
                        <template v-slot:item.is_grant="{ item }">
                            {{item.is_grant ? 'Yes' : 'No'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                          <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="$router.push('edit-client/'+item.user_id)"
                            x-small
                            color="blue"
                          >
                           <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                         <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="dialogPassword = true, selectedPasswordId = item.user_id,selectedPassword = generatePassword()"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              key
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.password_reminder="{ item }">
                             <v-btn
                            class="mx-2"
                            fab
                            dark
                            @click="remindPassword(item.user_id)"
                            x-small
                            color="primary"
                          >
                            <v-icon dark>
                              mdi-email-sync
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            text
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'green':'red'"
                          >
                          <v-icon v-if="item.status==1" size="40" class="material-icons-round opacity-10" dark>
                              toggle_on
                            </v-icon>
                            <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                              toggle_off
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.enable_grant="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            text
                            :disabled="role.name!='super_admin'"
                            @click="changeStatusGrant(item.user_id,item.enable_grant)"
                            x-small
                            :color="item.enable_grant==1 ? 'green':'red'"
                          >
                          <v-icon v-if="item.enable_grant==1" size="40" class="material-icons-round opacity-10" dark>
                              toggle_on
                            </v-icon>
                            <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                              toggle_off
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                        <template v-slot:item.process1="{ item }">
                          <v-btn
                            class="mx-2"
                            dark
                            @click="dialogDoc = true, selected_id = item.user_id"
                            x-small
                            v-if="item.process_1 == 0"
                            :color="'green'"
                          >
                          Start
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            disabled

                            @click="dialogDoc = true"
                            x-small
                            v-if="item.process_1 == 1"
                            :color="'green'"
                          >
                          Finished
                          </v-btn>
                        </template>
                        <template v-slot:item.process_1_key="{ item }">
                          <v-btn
                            class="mx-2"
                            
                            @click="dialogDoc = true, selected_id = item.user_id"
                            x-small
                            disabled
                            v-if="item.process_1 == 0"
                            :color="'green'"
                          >
                          View
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            dark
                            @click="getProcessStatus(item.process_1_key)"
                            x-small
                            v-if="item.process_1 == 1"
                            :color="'blue'"
                          >
                          View
                          </v-btn>
                        </template>
                        <template v-slot:item.request="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="selected_bot = item.user_id,creditCheck()"
                            x-small
                            color="green"
                          >
                           <v-icon size="20" class="material-icons-round opacity-10" dark>
                              play_circle
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItem(item.user_id)"
                            x-small
                            color="red"
                          >
                           <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                            class="pagination"
                            circle
                            color="#4CAF50"
                                v-model="page"
                                :length="pageCount"
                                @input="getUsers"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
        </v-row>
        <v-dialog
          v-model="dialogDoc"
          width="700"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Process 1
            </v-card-title>
            <div  class="mt-5 mb-5">
             <v-card-text >
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="StartProcess1(1)"
                            :color="'green'"
                          >
                          Server 1
                          </v-btn>
              </span>
            </v-card-text>
            <v-card-text >
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="StartProcess1(2)"
                            :color="'green'"
                          >
                          Server 2
                          </v-btn>
              </span>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogDoc = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
        persistent
      v-model="dialogImport"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Import client from CSV
        </v-card-title>

        <v-card-text>
           <rotate-loader class="mt-3" :loading="loadingUpload" color="#1D75F4"></rotate-loader>
           <h3 v-if="loadingUpload"> Importing...</h3>
          <v-file-input
              show-size
              class="mt-15"
              
              :disabled="loadingUpload"
              v-model="uploadedDocumentCSV"
              accept="application/csv"
              prepend-icon="fa-paperclip"
              label="Upload CSV"
          ></v-file-input>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingUpload"
            @click="uploadFiles()"
          >
            Upload
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingUpload"
            @click="dialogImport = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
         <v-dialog
          v-model="process_status_dialog"
          width="700"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Transaction {{transaction.Key}}
            </v-card-title>
            <div  class="mt-5 mb-5">
             <v-card-text >
              <span class="text-h5" >
                <b>Status: </b>
              </span>
              <span class="text-h5" v-if="!transaction.ProcessingException" >
                {{transaction.Status}}
              </span>
              <span class="text-h5" v-else >
                {{transaction.Status}} ({{transaction.ProcessingException.Reason}})
              </span>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="process_status_dialog = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
        persistent
      v-model="dialogImport2"
      width="500"
    >
  
      <v-card>
        
        <v-card-title class="text-h5 grey lighten-2">
          Submit new request
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingUpload2" color="#1D75F4"></rotate-loader>
                <span style="color:#5D6167;">Process</span>
                 <v-select
                            :disabled="loadingUpload2"
                            v-model="request.process"
                            :items="itemOptions"
                            ></v-select>
                <span style="color:#5D6167;">Round</span>
                 <v-select
                            :disabled="loadingUpload2"
                            v-model="request.round"
                            :items="[1,2,3,4]"
                            ></v-select>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingUpload2"
            @click="submitRequest()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingUpload2"
            @click="dialogImport2 = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        <v-overlay
          :absolute="true"
          :value="creditFlag"
          :opacity=".5"
        >
          <v-btn
            color="blue"
            class="
                        float-right
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          ms-auto
                          ml-5
                        "
            @click="$router.push('/admin/pricing')"
          >
            Buy Credit
          </v-btn>
          <v-btn
           class="
                        float-right
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          ms-auto
                          mr-5
                        "
            color="black"
            @click="creditFlag = false,dialogImport2 = false"
          >
            Close
          </v-btn>
        </v-overlay>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogPassword"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Reset Password
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingUpload2" color="#1D75F4"></rotate-loader>
           <v-radio-group   v-model="selectedPasswordRadio">
                 <v-radio
                    :label="`Automatically generate a password`"
                    :value="1"
                   
                  ></v-radio>
                    <v-text-field
                        id="name"
                        name="name"
                        class="mt-3"
                        style="password"
                        v-if="selectedPasswordRadio == 1"
                        label="Password"
                        width="300px"
                        ref="name"
                        v-model="selectedPassword"
                        />
                   <v-radio
                    :label="`Create password`"
                    :value="0"
                  ></v-radio>
                    <v-text-field
                        id="name"
                        name="name"
                        class="mt-3"
                        style="password"
                        v-if="selectedPasswordRadio == 0"
                        label="Password"
                        width="300px"
                        ref="name"
                        v-model="selectedPassword"
                        />
           </v-radio-group>
         

        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingUpload2"
            @click="resetPassword(selectedPasswordId)"
          >
            Reset
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingUpload2"
            @click="dialogPassword = false,selectedPasswordRadio = 1"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogAssign"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Assign to new Company
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingAssign" color="#1D75F4"></rotate-loader>
                <span style="color:#5D6167;">Company</span>
                 <v-select
                            item-text="name"
                            placeholder="Select company"
                            item-value="id"
                            :disabled="loadingAssign"
                            v-model="selectedCompany"
                            :items="companies"
                            ></v-select>
              
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="changeCompany('all',1)"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssign = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
    <vue-footer-admin></vue-footer-admin>
</v-main>

</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import {Circle8} from 'vue-loading-spinner'
import moment from 'moment';
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
import VueJsonToCsv from 'vue-json-to-csv'
export default {
    components : {
      'RotateLoader': ClipLoader,
      VueJsonToCsv
    },
    data () {
      return {
        header : { headers : {
            Authorization:"Bearer " + localStorage.getItem('token')
            },
        },
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 3 || 'Min 3 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
        transaction : [],
        loading:true,
        dialog:false,
        selectedPasswordId:'',
        dialogAssign: false,
        selectedPasswordRadio: 1,
        selectedPassword:'',
        initials:'',
        valid:true,
        user:'',
        creditFlag: false,
        selectedCompany:'',
        loadingAssign: false,
        selectedItems:[],
        request: {
            process: "609",
            round: 1,
            recaptcha: ""
        },
        pageCount:1,
        pageSort: {},
        dialogImport: false,
        dialogImport2 : false,
        selected_bot : "",
        documentList:[],
        passwordDisable: true,
        dialogPassword: false,
        totalCount:0,
        loadingUpload2:false,
        page:1,
        companies: [],
        itemsPerPage:10,
        uploadedDocumentCSV:[],
        itemOptions: ['609','Inquires','609 and Inquires','Bankrupcy','Identity Thieft'],
        selected_job:'',
        search : "",
        dialogDoc:false,
        loadingUpload: false,
        role: JSON.parse(localStorage.getItem('role')),
        datatable_options:{},
        offset: 0,
        selected_id : '',
        limit: 10,
        selected_project:'',
        checkbox: '',
        process_status_dialog : false,
        headers_jobs: [
          { text: 'Name', value: 'name',
          width: '150',
            sortable: true },
          { text: 'Email', value: 'email',
            sortable: true },
          { text: 'Phone', value: 'phone',
          width: '150',
            sortable: true },
            { text: 'Date of birth', value: 'date_of_birth',
            width: '200',
            sortable: true },
            { text: 'Address', value: 'address',
            width: '150',
            sortable: true },
            { text: 'Status', value: 'status',
            sortable: true },
            { text: 'Display Grant', value: 'enable_grant',
            sortable: true },
            { text: 'Company', value: 'company',
            sortable: true,width: '150' },
            { text: 'Round', value: 'current_process',
            sortable: false },
            { text: 'Grant', value: 'is_grant',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            { text: 'Documents', value: 'document',
            sortable: false,width: '150' },
            { text: 'Change Status', value: 'status_id',
            sortable: false,width: '150' },
            { text: 'Edit', value: 'edit',
            sortable: false,width: '150' },
            { text: 'Reset Password', value: 'password',
            sortable: false,width: '150' },
            // { text: 'Password Reminder', value: 'password_reminder',
            // sortable: false,width: '150' },
            { text: 'New Request', value: 'request',
            sortable: false,width: '150' },
            // { text: 'Process 1', value: 'process1',
            // sortable: false,width: '150' },
            // { text: 'Process 1 Status', value: 'process_1_key',
            // sortable: false,width: '150' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        projects: [
          
        ],
        users: []
      }
    },
    created () { 
        if(!localStorage.getItem('token'))
            this.$router.push('/login')
        // this.getUsers();
        this.getCompanies();
    },
    methods:{
      creditCheck(){
        this.getUserProfile();
        

      },
      getUserProfile() {
    let flag = 1;
    let self = this;
    self.loading = true;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/user",header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.user = data.user;
              let settings = localStorage.getItem('settings');
              settings = JSON.parse(settings);
              let item = 1;
              if(self.selectedItems.length != 0){
                item = item * self.selectedItems.length;
              }
              if(parseInt(this.user.credit) < (parseInt(settings.request_charge) * item) && this.role.name !='super_admin'){
                this.creditFlag = true;
                this.dialogImport2 = true;
              }else{
                this.dialogImport2 = true;
                this.creditFlag = false;
              }
              self.loading = false;
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
                self.loading = false;
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
      submitRequest(){
            let self = this;
            let flag = 1;
            let url ='';
            if (self.selected_bot || self.selectedItems.length != 0) {
                if(self.selectedItems.length != 0){
                  self.request.multiple_flag = 1
                  self.request.ids = self.selectedItems;
                  url = "/api/user-add-request-admin/all"
                }else{
                  self.request.multiple_flag = 0
                  url = "/api/user-add-request-admin/"+this.selected_bot
                }

                this.loadingUpload2 = true;
                axios({
                method: "POST",
                 
                url: url,
                data:self.request,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.success != "False"){
                        // self.$router.push('/thanks');
                        Vue.$toast.success('Request submitted', {
                            position: 'top-right'
                            }); 
                         
                            self.loadingUpload2 = false;
                            self.dialogImport2 = false;
                            self.getUsers();
                            localStorage.setItem('credit', response.data.new_credit)
                        // location.reload();
                    }else{
                        this.isDark = true;
                        this.isDisable = false;
                        Vue.$toast.error(response.data.result.errors.error_message, {
                            position: 'top-right'
                            }); 
                    }
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    this.isDark = true;
                    this.isDisable = false;
                    let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                    if(data.email){
                        Vue.$toast.error(data.email[0], {
                        position: 'top-right'
                        });
                    }else if(data.password){
                        Vue.$toast.error(data.password[0], {
                        position: 'top-right'
                        });
                    }
                    else if(data.user_name){
                        Vue.$toast.error(data.user_name[0], {
                        position: 'top-right'
                        });
                    }
                    else{
                        Vue.$toast.error(data, {
                        position: 'top-right'
                        });
                    }
                    self.onCaptchaExpired()

                });

            }else{
              Vue.$toast.error('Select atleast one item', {
                        position: 'top-right'
                        });
            }
        },
      dateFormat(date){
        return moment(date).format("MM/DD/YYYY")+', Age '+moment().diff(date, 'years');
      },
        formatDate(value) {
            return moment(value).format("DD MMM YYYY")
        },
        StartProcess1(flag){
          let id = this.selected_id;
          var r = confirm("Are you sure, You went to start process 1?");
            if (r == true) {
              this.loading = true;
              this.dialogDoc = false;
                    axios
                .get("/api/process1/"+id+"/"+flag,this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Process started successfully', {
                            position: 'top-right'
                            });
                            this.loading = false;
                            this.getUsers();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                            this.loading = false;
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                return false;
            }
        },
        openDocModal(id){
          
          this.dialogDoc = true;
          axios
            .get("/api/get-documents/"+id,this.header)
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.documentList = data.data;
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
           });
        },
        editItem(id){
          this.$router.push('/add-parking/'+id)
        },
        deleteItem(id){
         let multiple_flag = 0
        if(id == 'all')
            multiple_flag = 1
          var r = confirm("Are you sure, You went to delete?");
           if(id == 'all' && this.selectedItems.length == 0){
            Vue.$toast.error("Select atleast one item to delete", {
              position: 'top-right'
              });
              r = false
          }
          if (r == true) {
            axios
              .post("/api/delete-profile/"+id,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getUsers();
                    this.selectedItems = [];
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        changeCompany(id,value){
          this.loading = true
            let multiple_flag = 0
            if(id == 'all')
                multiple_flag = 1
              var r = confirm("Are you sure, You went to change company?");
              if(id == 'all' && this.selectedItems.length == 0){
                Vue.$toast.error("Select atleast one item", {
                  position: 'top-right'
                  });
                  r = false
                  this.loading = false
              }
            if (r == true) {
              if(!this.selectedCompany){
                      Vue.$toast.error('Select Company', {
                      position: 'top-right'
                      });
                  }else{
                    this.loadingAssign = true;
                    axios
                .post("/api/change-company/"+id+"/"+this.selectedCompany,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Client assigned to new company successfully', {
                            position: 'top-right'
                            });
                            this.dialogAssign = false;
                            this.loadingAssign = false;
                            this.getUsers();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                  }
                
            }else{
                return false;
            }
        },
        changeStatus(id,value){
            this.loading = true
            let multiple_flag = 0
            if(id == 'all')
                multiple_flag = 1
              var r = confirm("Are you sure, You went to change status?");
              if(id == 'all' && this.selectedItems.length == 0){
                Vue.$toast.error("Select atleast one item", {
                  position: 'top-right'
                  });
                  r = false
                  this.loading = false
              }

            if (r == true) {
                    axios
                .post("/api/change-status/"+id+"/"+value,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Profile status changed successfully', {
                            position: 'top-right'
                            });
                            this.getUsers();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
              this.loading = false
                return false;
            }
        },
        changeStatusGrant(id,value){
            this.loading = true
            let multiple_flag = 0
            if(id == 'all')
                multiple_flag = 1
              var r = confirm("Are you sure, You went to change grant?");
              if(id == 'all' && this.selectedItems.length == 0){
                Vue.$toast.error("Select atleast one item", {
                  position: 'top-right'
                  });
                  r = false
                  this.loading = false
              }

            if (r == true) {
                    axios
                .post("/api/change-status-grant/"+id+"/"+value,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Grant status changed successfully', {
                            position: 'top-right'
                            });
                            this.getUsers();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
              this.loading = false
                return false;
            }
        },
        generatePassword() {
            var length = 8,
                charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                retVal = "";
            for (var i = 0, n = charset.length; i < length; ++i) {
                retVal += charset.charAt(Math.floor(Math.random() * n));
            }
            return retVal;
        },
        uploadFiles(){
            let self = this;
            let flag = 1;
            if(self.uploadedDocumentCSV.length == 0){
                Vue.$toast.error('Select document', {
                position: 'top-right'
                });
                flag = 0;
            }
            else{
                self.loadingUpload = true;
                const data = new FormData();
                data.append('client_csv', self.uploadedDocumentCSV);
                axios({
                method: "POST",
                url: "/api/import-clint-csv",
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                data: data
                })
                .then(response => {
                console.log(response);
                self.loadingUpload = false;
                 Vue.$toast.success('Client imported successfully', {
                            position: 'top-right'
                            });
                self.getUsers();
                self.uploadedDocumentCSV = [];
                self.dialogImport = false;
                // this.isDisableUpload = false;
                // this.isDarkUpload = true;
                // self.e1 = 3;
                
                })
                .catch(err => {
                    Vue.$toast.error("Invalid file to upload", {
                    position: 'top-right'
                    });
                    self.loadingUpload = false;
                    // this.isDisableUpload = false;
                    // this.isDarkUpload = true;
                });
            }
        },
        getCompanies(){
          
            axios({
          method: "GET",
          url: "/api/get-all-company",
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.companies = data.data;
                
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        getUsers(){
            this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-clients",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.users = data.data;
                this.pageCount = data.total_page
                this.totalCount = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        resetPassword(id){
          this.loading = true
             var r = confirm("Are you sure, You went to reset password?");
            if (r == true) {
                    axios
                .post("/api/reset-password/"+id,{password: this.selectedPassword},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        this.loading = false
                        Vue.$toast.success('Password reset successfully', {
                            position: 'top-right'
                            });
                            this.selectedPasswordRadio = 1;
                            this.dialogPassword = false;
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                this.loading = false
                return false;
            }
        },
        remindPassword(id){
          this.loading = true
             var r = confirm("Are you sure, You went to send password to client?");
            if (r == true) {
                    axios
                .get("/api/remind-password/"+id,this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        this.loading = false
                        Vue.$toast.success('Password reminder sent successfully', {
                            position: 'top-right'
                            });
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                this.loading = false
                return false;
            }
        },
        getProcessStatus(id){
            this.loading = true;
            this.offset = (this.page - 1) * 10
            axios({
              method: "GET",
               
              url: "/api/get-process-status/"+id,
              header : { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
                }
            })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.transaction = data.data.value[0];
                console.log(this.transaction);
                this.process_status_dialog = true;
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        
        

    },
    watch: {
            datatable_options: {
                handler () {
                    console.log(this.datatable_options);
                this.getUsers()
                },
                deep: true,
            },
            selectedPasswordRadio:{
              handler(val){
                console.log(val);
                if(val == 1){
                  this.selectedPassword = this.generatePassword();
                }else{
                  this.selectedPassword = '';
                }
              }
            },
            // itemsPerPage:{
            //   handler(val){
            //     console.log('ss',val);
            //     this.itemsPerPage = 50
            //   }
            // }
        },
    
}
</script>

