<template>
  <div id="app">
    <div class="h-100">
                      <p class="mt-1 mb-5"> <span class="mb-1 text-h4 text-typo font-weight-bold text-center">
                          AI Assistant
                        </span>
                      
                        </p>

                      </div>
                      <v-divider class="mb-5"></v-divider>
          <v-row class="">
            <v-col
              cols="12" sm="3"
              class="flex-grow-1 flex-shrink-0"
              style="border-right: 1px solid #0000001f;"
            >
              <v-responsive
                class="overflow-y-auto fill-height"
              >
                <v-list subheader>
                  <v-list-item-group v-model="activeChat">
                    <template v-for="(item, index) in conversations">
                      <v-list-item
                        :key="`parent${index}`"
                        :value="item.id"
                      >
                        
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px;" class="bold" v-text="item.type" />
                        </v-list-item-content>

                      </v-list-item>
                      <v-divider
                        :key="`chatDivider${index}`"
                        class="my-0"
                      />
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-responsive>
            </v-col>
            <v-col
              cols="12" sm="9"
            >
            
              <v-responsive
                v-if="activeChat"
                class="overflow-y-hidden fill-height"
                height="700"
              >
                <v-card
                  flat
                  class="d-flex flex-column fill-height"
                >
                  <v-card-title>
                    Personal Credit Building
                  </v-card-title>
                  <div><rotate-loader style="float:right; " :loading="overlay" color="#1D75F4"></rotate-loader></div>
                  <v-card-text class="flex-grow-1 overflow-y-auto ai-chat">
                   
                    <template v-for="(msg, i) in messages">
                     
                    
                      <div
                        :class="{ 'd-flex flex-row-reverse': msg.role =='user' }"
                      >
                      
      
                              <v-chip
                                :color="msg.role =='user' ? '#04AA6D' : 'primary'"
                                dark
                                style="height:auto;white-space: normal;"
                                class="pa-4 mb-2"
                                v-on="on"
                              >
                                <div v-html="renderedMarkdown(msg.content[0].text.value,msg.created_at)"></div>
                                <!-- <div
                                  class="ml-2"
                                  style="font-size: .55rem;"
                                >{{ getFormattedDate(msg.created_at) }}</div> -->
                               
                              </v-chip>
                            
                      </div>
                    </template>
                  </v-card-text>
                  <v-card-text class="flex-shrink-1" v-if="selectedConversation.status != 'Ended'">
                  <v-textarea
                        clearable
                        v-model="user_message"
                        style="margin-bottom: -20px;"
                        clear-icon="mdi-close-circle"
                        placeholder="Type here..."
                        filled
                        >
                        <template v-slot:prepend>
                            <v-icon @click="onButtonClickProof" class="material-icons-round " style="margin-top: 50px;font-size: 25px;margin-left: -15px;cursor: pointer;">attach_file</v-icon>
                        </template>
                        <template v-slot:append-outer>
                          
                          <v-icon v-if="!loaderChat"color="green" class="material-icons-round " style="margin-top: 50px;font-size: 25px;margin-right: -15px;cursor: pointer;" @click="saveConversation">send</v-icon>
                          <v-progress-circular
                            indeterminate
                            v-if="loaderChat"
                            style="margin-top: 45px;"
                            color="green"
                          ></v-progress-circular>
                      </template>
                    
                    </v-textarea>
                    <input
                              :ref="'file'"
                              class="d-none"
                              type="file"
                              multiple
                              accept="application/pdf,image/png, image/jpeg, image/bmp, image/gif,.doc,.docx,.txt"
                              @change="onFileChanged"
                            >
                            <v-chip
                            v-for="(file, index) in selectedFiles"
                            class="ma-2"
                            close
                            @click:close="removeFile(index)"
                          >
                            {{file.name}}
                          </v-chip>
                    </v-card-text>
                    <v-card-text style="margin-top: -15px;" v-if="messages.length != 1 && selectedConversation.status != 'Ended'">
                        <v-progress-linear :value="progress" color="green accent-4" ></v-progress-linear>
                        <div style="text-align: center;">
                        <v-btn
                                                    depressed
                                                    style="text-transform: none;"
                                                    color="red"
                                                    class="
                                                      font-weight-normal
                                                      text-capitalize
                                                      ms-auto
                                                      btn-primary
                                                      
                                                      py-3
                                                      px-6
                                                      ms-3
                                                      mt-3
                                                    "
                                                    @click="endConversation"
                                                  >
                                                    END CHAT
                                                  </v-btn>
                                                  </div>
                    </v-card-text>
                </v-card>
              </v-responsive>
            </v-col>
          </v-row>
</div>
</template>

<script>
import moment from 'moment';
import marked from 'marked';
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
export default {
  name: "aibot",
  props : ['name','user_id'],
  components: {
    'RotateLoader': ClipLoader
  },
  data: () => ({
    activeChat: '',
    conversations : [],
    messages: [],
    user_message :'',
    user_file : '',
    overlay :true,
    progress : 0,
    selectedConversation : '',
    loaderChat : false,
    parents: [
      {
        id: 1,
        title: "Personal Credit Building",
        active: true
      },
      {
        id: 3,
        title: "Personal Credit Disputing",
        active: false
      },
      {
        id: 4,
        title: "Removal from Public Records",
        active: false
      },
      {
        id: 5,
        title: "Business Credit Building",
        active: false
      },
      
    ],
    
    messageForm: {
      content: "",
      me: true,
      created_at: "11:11am"
    },
    selectedFiles: [],
  }),
  created(){
    this.getConversations();
  },
  methods: {
    renderedMarkdown(data,date) {
      let html = marked.parse(data);
      const pattern = /【\d+:\d+†[^】]+】/g;
      html = html.replace(pattern, '');
      if(this.activeChat != -1)
        html = html + "<div style='float:right'>"+this.getFormattedDate(date)+"</div>";
      return  html;
    },
    onButtonClickProof() {
      this.$refs['file'].click()
    },
    onFileChanged(e) {
      let self = this;
      self.selectedFiles = Array.from(e.target.files)
      console.log(self.selectedFiles);
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
    },
    getFormattedDate(date){
          return moment.unix(date).format('MMMM Do YY, h:mm a');
        },
    getConversations(){
      const id = this.user_id;
       axios({
          method: "GET",
          url: "/api/get-ai-conversations/"+id,

          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.conversations = data.conversations;
              if(this.conversations.length == 0){
                this.conversations.push({
                  id: -1,
                  type: "New Conversation",
                  active: true,
                  ai_id: ''
                },)
                this.activeChat = -1;
                this.overlay = false
              }else{
                //this.messages = this.conversations[0].messages
                if(this.conversations[0].status == 'Ended'){
                      
                    const arr = [{
                      id: -1,
                      type: "New Conversation",
                      active: true,
                      ai_id: ''
                    }];
                    this.conversations = arr.concat(this.conversations);
                    this.activeChat = -1;
                    this.overlay = false
                }else{
                  this.getMessage(this.conversations[0].ai_id);
                  this.activeChat = this.conversations[0].id
                }
              }
            }
            })
    },
    endConversation(){
      if(confirm("Are you sure, You want to end the conversation?")){
        axios({
            method: "GET",
            url: "/api/end-ai-conversation/"+this.activeChat,

            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
          })
          .then(response => {
              let data = response.data.data;
              if(data){
                this.getConversations();
              }
            })
      }
    },
    getMessage(id){
      if(id){
        this.overlay = true,
        axios({
            method: "GET",
            url: "/api/get-ai-messages/"+id,

            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
          })
          .then(response => {
              let data = response.data.data;
              if(data){
                this.messages = data.conversations;
                this.progress = this.messages.length
                this.overlay = false
              }
              })
      }else{
        this.messages.push({
        id: '',
        conversation_id : -1,
        ai_id : '',
        content : [{text : {value : `Hi `+this.name+`, I'm Alex, your personal credit coach! I'm here to guide you through every step of your credit building journey. If you ever have questions or need assistance, I'm just a message away. Let's get started on improving your credit score together! <br/><br/>
        Remember, your progress is saved, so you can always take a break, log out, and come back later to pick up where you left off.`}}],
        rold : 'assistent',
        is_ai : true,
        created_at : Date.now()

      });
      this.overlay = false;
      }
    },
    saveConversation(){
        let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
          if(!this.user_message && this.selectedFiles.length == 0){
            Vue.$toast.error("Message is required! ", {
              position: 'top-right'
              });
          }else if(this.selectedFiles.length > 3){
            Vue.$toast.error("You can upload only 3 files at a time. ", {
              position: 'top-right'
              });
          }else{
              this.loaderChat = true;
              let id  = this.user_id;
              const data = new FormData();
              data.append('user_id',id);
              data.append('conversation_id',this.activeChat);
              data.append('conversation_type','Personal Credit Building');
              data.append('message',this.user_message);
              data.append('ai_id',this.selectedConversation.ai_id);
              if(this.selectedFiles.length > 0){
                for (let i = 0; i < this.selectedFiles.length; i++) {
                    data.append('files[]', this.selectedFiles[i]);
                }
              }
              axios
                .post("/api/save-ai-conversation",data,header)
                .then(response => {
                  this.getConversations()
                  this.loaderChat = false;
                  this.user_message = '';
                  this.selectedFiles = [];
                })
                .catch(err => {
                  this.loaderChat = false
                              Vue.$toast.error(err.response.data.message, {
                              position: 'top-right'
                              });
                              
                          });
          }
        },
  },
  watch : {
    activeChat(value) {
      this.messages = [];
      this.conversations.forEach(conversation => {
        if(conversation.id == value){
          this.selectedConversation = conversation;
          this.getMessage(conversation.ai_id);
        }
      })
      
    }
  }
}
</script>

<style >
.ai-chat a {
  color: #2196F3!important;
  text-decoration: none;
}
.ai-chat a:hover {
  color: #2196F3!important;
  text-decoration: none;
}
.ai-chat a, a:active {
  color: #2196F3!important;
  text-decoration: none;
}
</style>